@import "variables";
@import "ui/mixins";

// Core CSS
@import "ui/utilities";
@import "ui/type";
@import "ui/button";
@import "ui/tables-responsive";

// Components
@import "ui/badge";
@import "ui/blockquote";
@import "ui/box";
@import "ui/callout";
@import "ui/call-to-action";
@import "ui/card-blog";
@import "ui/card-box";
@import "ui/card-form";
@import "ui/card-icon";
@import "ui/card-image";
@import "ui/card-number";
@import "ui/card-portfolio";
@import "ui/card-product";
@import "ui/card-profile";
@import "ui/cover";
@import "ui/feature-callout";
@import "ui/forms";
@import "ui/hero";
@import "ui/hover";
@import "ui/hover-basic";
@import "ui/hover-link";
@import "ui/icon";
@import "ui/icon-social";
@import "ui/images";
@import "ui/list";
@import "ui/loader";
@import "ui/pricing-tables";
@import "ui/ribbon";
@import "ui/sash";
@import "ui/testimonials";
@import "ui/timeline";


.ant-tree li .ant-tree-node-content-wrapper {
  width: 100%;
  padding-left: 24px;
  margin-left: -24px;
  display: inline-block;
  height: 40px;
  margin: 0;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 40px;
  text-decoration: none;
  vertical-align: top;
  border-radius: 2px;
  cursor: pointer;
  // -webkit-transition: all 0.3s;
  // transition: all 0.3s;
}

.ant-tree li span.ant-tree-switcher, .ant-tree li span.ant-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 40px;
  margin: 0;
  line-height: 40px;
  text-align: center;
  vertical-align: top;
  border: 0 none;
  outline: none;
  cursor: pointer;
}

.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected,
.ant-tree li .ant-tree-node-content-wrapper:hover {
  border-right: 4px solid #1890ff;
  padding-left: 24px;
  margin-left: -24px;
}

.ant-progress-status-success .ant-progress-text {
  display: inline !important;
}
